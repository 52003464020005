<template>
    <div class="role_main">
        <!-- 标题 -->
        <div class="title_page">
            <div class="sig"></div>
            <div class="page_name">广告位列表</div>
        </div>
        <!-- 表格内容 -->
        <div class="avatar_table">
            <el-card>
                <!-- 添加 删除 下拉框 按钮部分 -->
                <div class="out_sel_btn">
                    <div class="sel_inp">
                        <el-input placeholder="请输入广告名称" prefix-icon="el-icon-search" v-model="search_inp" size="mini"
                            clearable @change="initData">
                        </el-input>
                    </div>
                    <div class="add_del_btn">
                        <el-button type="primary" class="addBtn" @click="addition">新建</el-button>
                        <!-- <el-button class="delBtn" @click="deltion">删除</el-button> -->
                    </div>
                </div>
                <!-- 内容表格部分 -->
                <div class="table_init">
                    <el-table :data="tableData" tooltip-effect="dark" style="width: 100%"
                        :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                        <el-table-column prop="uid" label="ID" v-if="show=false">
                        </el-table-column>
                        <el-table-column prop="type" label="广告板块">
                        </el-table-column>
                        <el-table-column prop="img_name" label="广告备注">
                        </el-table-column>
                        <el-table-column prop="position_id" label="广告位置">
                        </el-table-column>
                        <el-table-column prop="sort" label="排序">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <span style="margin-right:16px" @click="handle_Edit(scope.row.uid)">编辑</span>
                                <span @click="handle_Delet(scope.row.uid)">删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页部分 -->
                <Paging :count="count" :initData="initData" @changePage="handle_changePage"></Paging>
            </el-card>
        </div>
        <!-- 弹框部分 -->
        <!-- 添加编辑弹框 -->
        <el-dialog :visible.sync="add_edit_dialog" width="828px" :before-close="handleClose" @close="handle_reset">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">{{title_name}}</div>
            </div>
            <!-- 表单验证内容 -->
            <el-form label-width="80px">
                <el-form-item label="广告位置">
                    <el-radio v-model="radio" label="1">顶部</el-radio>
                    <el-radio v-model="radio" label="2">中部</el-radio>
                    <el-radio v-model="radio" label="3">底部</el-radio>
                </el-form-item>
                <el-form-item label="广告板块">
                    <el-select v-model="principal_id" placeholder="请选择广告位置" clearable size="mini">
                        <el-option v-for="item in principal" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="sort_num" placeholder="请输入排序序号(例:1)" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="广告说明">
                    <el-input type="textarea" v-model="pos_content" placeholder="请输入广告说明" clearable style="width:472px">
                    </el-input>
                </el-form-item>
                <el-form-item label="默认照片" class="img_load">
                    <div style="display:flex">
                        <el-input style="width:376px;height:30px" placeholder="请上传1张广告位图片" disabled></el-input>
                        <el-upload class="upload-demo" action="https://education.ztxinde.com/adver_set_upload"
                            :headers=myHeaders accept=".png,.gif,.jpg,.jpeg" name="image"
                            :on-success="handleAvatarSuccess" :show-file-list="false" :before-upload="handele_img">
                            <el-button type="primary" style="width:80px;height:30px;margin-left:16px" size="small">上传
                            </el-button>
                        </el-upload>
                    </div>
                </el-form-item>
                <el-form-item v-if="img_show">
                    <img style="width:100px;height:100px" :src="img_url" alt="">
                </el-form-item>
            </el-form>
            <!-- 提交 重置 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="add_confirm" @click="handle_confirm">{{btn_name}}</el-button>
                <el-button class="add_Reset" @click="handle_reset">重置</el-button>
            </div>
        </el-dialog>
        <!-- 删除弹框 -->
        <el-dialog :visible.sync="del_dialog" width="567px" :before-close="handleClose" @close="remove_Fn">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">删除</div>
            </div>
            <div class="del_content">{{del_tip}}</div>
            <!-- 提交 取消 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="del_confirm" @click="handle_del">确定</el-button>
                <el-button class="del_Reset" @click="remove_Fn">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { getPositApi, showPositApi, addPositApi, editPositApi, removePositApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // token
            myHeaders: {
                'Token': ''
            },
            // 登录用户id
            user_id: '',
            // 弹框显示隐藏
            add_edit_dialog: false,   //添加编辑弹框
            del_dialog: false,    //删除弹框
            // 每条数据id
            id: '',
            //  表格数据
            tableData: [],
            multipleSelection: [],
            // 分页
            page: 1,
            count: 0,
            max: 10,
            // 搜索输入框
            search_inp: '',
            // 弹框标题  是添加还是编辑
            title_name: '',
            btn_name: '',
            // *********表单验证内容*********
            radio: '1',   //位置单选
            principal: [],     //广告位置
            principal_id: '',
            sort_num: '',   //排序
            pos_content: '',   //广告说明
            // 上传图片地址
            img_url: '',
            // 图片是否显示
            img_show: false,
            // 删除数据的数组
            del_arr: [],
            // 删除提示语
            del_tip: '',
        }
    },
    created () {
        this.initData();
    },
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        },
    },
    methods: {
        // 子组件传过来的当前页数
        handle_changePage (data) {
            this.page = data;
        },
        // 初始化数据
        initData () {
            this.user_id = sessionStorage.getItem('id');
            getPositApi({
                page: this.page,   //当前页
                img_name: this.search_inp,  //广告名称
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 弹窗关闭
        handleClose () {
            this.add_edit_dialog = false;
            this.del_dialog = false;
        },
        // 点击添加按钮
        addition () {
            this.add_edit_dialog = true;
            this.title_name = '添加';
            this.btn_name = '确认添加';
            showPositApi({
                type: 1,
                auth_userid: this.user_id   //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.principal = res.advers;    //广告位置数据
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 广告图片上传前
        handele_img () {
            this.myHeaders.Token = sessionStorage.getItem("Token");
        },
        // 广告图片上传后
        handleAvatarSuccess (res, file, fileList) {
            if (res.status.code == 200) {
                this.img_show = true;
                this.$message.success('图片上传成功！');
                this.img_url = res.status.img;
            } else if (res.status.code == 201) {
                this.img_show = false;
                this.$message.error(res.status.msg)
            } else if (res.status.code == 202) {
                this.$message.error('登录过期，请重新登录！');
                this.$router.push({ path: '/login' })
            }
        },
        // 点击编辑按钮
        handle_Edit (id) {
            this.add_edit_dialog = true;
            this.id = id;
            this.title_name = '修改';
            this.btn_name = '确认修改';
            showPositApi({
                id: id,
                type: 2,
                auth_userid: this.user_id   //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.principal = res.advers;    //广告位置数据
                    this.principal_id = res.data.position_id;   //位置id
                    this.pos_content = res.data.img_name;    //广告说明
                    this.sort_num = res.data.sort;  //排序
                    let state_rad = res.data.type;   //具体位置
                    if (state_rad == 1) {
                        this.radio = '1'
                    } else if (state_rad == 2) {
                        this.radio = '2'
                    } else if (state_rad == 3) {
                        this.radio = '3'
                    };
                    this.img_url = res.data.default_img;  //图片
                    if (this.img_url == '') {
                        this.img_show = false;
                    } else {
                        this.img_show = true;
                    };
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加  编辑 点击提交按钮
        handle_confirm () {
            let state_rad = '';
            if (this.radio == '1') {
                state_rad = 1;
            } else if (this.radio == '2') {
                state_rad = 2;
            } else if (this.radio == '3') {
                state_rad = 3;
            };
            if (this.title_name == '添加') {
                addPositApi({
                    position_id: this.principal_id,  //位置id
                    img_name: this.pos_content, //广告备注
                    type: state_rad,  // 具体位置
                    default_img: this.img_url, //图片
                    auth_userid: this.user_id, //登录用户id
                    sort: this.sort_num,    //排序
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.title_name == '修改') {
                editPositApi({
                    id: this.id,
                    position_id: this.principal_id,  //位置id
                    img_name: this.pos_content, //广告备注
                    type: state_rad,  // 具体位置
                    default_img: this.img_url, //图片
                    auth_userid: this.user_id, //登录用户id
                    sort: this.sort_num,    //排序
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 添加  编辑  点击重置按钮  弹窗点击遮罩关闭
        handle_reset () {
            this.radio = '1';
            this.principal_id = '';
            this, this.sort_num = '';
            this.pos_content = '';
            this.img_show = false;
            this.img_url = ''
        },
        // 点击删除按钮  单个删除按钮
        handle_Delet (id) {
            this.id = id;
            this.del_dialog = true;
            this.del_tip = '确定删除此项？';
            this.del_arr.push(id);
        },
        remove_Fn () {
            this.del_dialog = false;
            this.del_arr.splice(0, this.del_arr.length)
        },
        // 删除点击确定按钮
        handle_del () {
            removePositApi({
                id: this.del_arr,
                auth_userid: this.user_id   //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.$message.success('删除成功');
                    this.del_dialog = false;
                    this.initData();
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.role_main {
    width: 100%;
    height: 100%;
    // 页面标题部分
    .title_page {
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .sig {
            width: 18px;
            height: 4px;
            margin-right: 8px;
            background: #2479dd;
        }
        .page_name {
            font-size: 18px;
            font-weight: 600;
        }
    }
    // 表格显示内容
    .avatar_table {
        margin-bottom: 20px;
        // 下拉框  添加  删除按钮部分
        .out_sel_btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // 下拉框部分
            .sel_inp {
                margin-top: 15px;
                display: flex;
                .el-input {
                    width: 155px;
                    height: 30px;
                    ::v-deep.el-input__inner {
                        width: 155px;
                        height: 30px;
                    }
                }
            }
            // 添加  删除按钮部分
            .add_del_btn {
                text-align: right;
                .el-button {
                    width: 80px;
                    height: 30px;
                    line-height: 4px;
                }
                .addBtn {
                    margin-right: 8px;
                }
            }
        }
        // 表格内容
        .table_init {
            margin-top: 20px;
            text-align: center;
            // 操作按钮
            span {
                border: none;
                width: 24px;
                height: 18px;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }
    // 弹框部分
    .el-dialog {
        text-align: center;
        // border-radius: 12px;
        // 标题部分
        .tipes {
            display: flex;
            align-items: center;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
            .sd {
                width: 18px;
                height: 4px;
                margin-right: 8px;
                background: #2479dd;
            }
            .ax {
                color: #000000;
                font-size: 16px;
                font-weight: 600;
            }
        }
        //  添加  编辑 表单部分
        .el-form {
            text-align: left;
            margin: 30px 0 50px 100px;
            .el-form-item {
                .el-input {
                    width: 472px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 472px;
                    height: 30px;
                }
            }
            //照片上传
            .img_load {
                ::v-deep.el-input__inner {
                    width: 376px;
                    height: 30px;
                }
            }
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 68px 0 80px;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .el-button {
                width: 98px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
            }
            // 确认按钮  添加  编辑
            .add_confirm {
                margin-right: 16px;
            }
            // 确认按钮  删除
            .del_confirm {
                margin-right: 16px;
            }
        }
    }
}
</style>